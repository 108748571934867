import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box,Card,Grid,Paper, styled, Typography } from '@mui/material';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [GenderData,setGenderData]=useState([]);
  const [ProgressData, setProgressData]=useState([]);
  const [PassingData, setPassingData]=useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}adm/userSuperFetch`);
        if(Array.isArray(response.data) && response.data.length > 0) {
          const flattenedData = response.data.map(user => ({
            ...user.Constants,
            _id: user._id,
            ...user.StageState,
             ...user,
          }));
          setData(flattenedData);
          // Count the number of males, females, and others
          const maleCount = flattenedData.filter(user => user.gender === 'male').length;
          const femaleCount = flattenedData.filter(user => user.gender === 'female').length;
          const otherCount = flattenedData.filter(user => user.gender !== 'male' && user.gender !== 'female').length;
          const RegCount = flattenedData.filter(user => user.FirstBoot === true).length;
          const TestCount =  flattenedData.filter(user => user.Exams === true).length
          const ProfileCount =  flattenedData.filter(user => user.Profile === true).length
          const PITCount =  flattenedData.filter(user => user.PITs === true).length
          const FaceCount = flattenedData.filter(user => user.Faces === true).length
          const AS_P_Count = flattenedData.filter(user => user.PMT.AttachmentStyle.Status === true).length
          const CO_P_Count = flattenedData.filter(user => user.PMT.Conscientiousness.Status === true).length
          const MS_P_Count = flattenedData.filter(user => user.PMT.Mindset.Status === true).length
          const LS_P_Count = flattenedData.filter(user => user.PMT.LifeSatisfaction.Status === true).length
 
   console.log(flattenedData)
          // Set the GenderData state
          setGenderData([
            { id: 0, value: maleCount, label: 'Male' },
            { id: 1, value: femaleCount, label: 'Female' },
            { id: 2, value: otherCount, label: 'Other' },
          ]);
          setProgressData([{ data: [
            RegCount, TestCount, ProfileCount,PITCount,FaceCount
          ] }])
          setPassingData([{ data: [
            AS_P_Count, CO_P_Count, MS_P_Count, LS_P_Count
          ] }])
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    setLoading(true);
    fetchData();
   }, [page]);
   
 
  if (loading) {
    return (
     <div style={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       height: '90vh',
       fontSize: '2em',
     }}>
       Loading...
     </div>
    );
  }
 
  return(
    <Box sx={{ height: "95%", m:"20px" }}>
     <Box sx={{ flexGrow: 1 }}>
     <Grid container spacing={2}>
 <Grid item xs={12} sm={6}>
  <Item style={{ height: '40vh' }}>
    <Typography variant="h5">Current User Base</Typography>
    <Typography variant="h1"><PeopleAltIcon style={{ fontSize: '5rem' }}/> {data.length}</Typography>
  </Item>
 </Grid>
 <Grid item xs={12} sm={6} sx={{p:"10px"}}>
 <Item style={{ height: '100%' }}>
 <Typography variant="h5">Gender Distribution</Typography>
 <div style={{ width: '100%', height: '100%' }}>
   <PieChart
     series={[
       {
         data:  GenderData && GenderData.length > 0 ? GenderData : [],
         innerRadius: 30,
         outerRadius: 100,
         paddingAngle: 5,
         cornerRadius: 5,
         startAngle: -90,
         endAngle: 180,
         cx: 150,
         cy: 150,
       },
     ]}
     width={400}
     height={200}
   /> 
 </div>
 </Item>
</Grid>

 <Grid item xs={12} sm={6}>
  <Item>
 <Typography variant="h5">Progress Stage Distribution</Typography>

  <BarChart
      xAxis={[{ scaleType: 'band', data: ['Register', 'Exam', 'Profile', 'PIT', 'Face'] }]}
      series={ProgressData}
      width={500}
      height={300}
    />
  </Item>
 </Grid>
 <Grid item xs={12} sm={6}>
 <Item style={{ height: '100%' }}>
 <Typography variant="h5">Passed across Pschyometrics</Typography>
 <div style={{ width: '100%', height: '100%' }}>
 <BarChart
      xAxis={[{ scaleType: 'band', data: ['AS', 'CO', 'MS', 'LS'] }]}
      series={PassingData}
      width={500}
      height={300}
    />
 </div>
 </Item>
 </Grid>
</Grid>

    </Box>
    </Box>
  );
}

export default Dashboard;
