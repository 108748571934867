import React, { useState } from 'react';
// import {jwt_decode} from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

import axios from 'axios';
import { Link } from 'react-router-dom';
import {  TextField, OutlinedInput, InputAdornment, IconButton,
          Box, Button, Card, Grid, CardActions, CardContent, CardMedia, Typography,
          Snackbar, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const [userData, setUserData] = useState({ email: '', password: '' });

  const { email, password } = userData;

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  const setCurrentUser = (user) => {
    console.log('Current User:', user);
  };

  const login = async () => {
    const user = {
      email,
      password,
    };
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify(user);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}adm/login`,
        body,
        config
      );
      localStorage.removeItem('userToken');
      localStorage.setItem('userToken', JSON.stringify(res.data.token));
      setAuthToken(res.data.token);
      const decoded = jwtDecode(res.data.token);
      setCurrentUser(decoded);
      window.location.href="/Dash"
    } catch (err) {
      setOpenSnackbar(true);
      console.log(err.response.data)
    }
  };

  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#D8234C',
    color:'white'
  }

// alert system for errors - snackbar 
const [openSnackbar, setOpenSnackbar] = React.useState(false);


  return (
    <div>
    <Grid
      container
      justifyContent="center"
      alignItems="top"
      style={{ minHeight: '100vh', marginTop:'10vh' }}
    >
      <Box>
     <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="200"
        image="./Admlogo.png"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" align="center" component="div">
          Login
        </Typography>
        <Typography gutterBottom variant="p" style={{textAlign:'right', color:'#D8234C', textDecoration:'none'}} component={Link} to={'/Register'}>
          Register
        </Typography>
      </CardContent>
      <CardActions>
      <Grid container justifyContent="center" alignItems="center">

        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          fullWidth
          name="email"
          value={email}
          onChange={(e) => onChange(e)}
          placeholder="Email"
          style={{marginTop:'1vh'}}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& fieldset": {
                borderColor: '#D8234C',
              },
            },
            "& label.Mui-focused": {
              color: '#D8234C',
            },
          }}
         />
        <OutlinedInput
          id="outlined-password-input"
          label="Password"
          name="password"
          fullWidth
          value={password}
          onChange={(e) => onChange(e)}
          placeholder="Password"
          style={{marginTop:'3vh'}}
          type={showPassword ? 'text' : 'password'}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& fieldset": {
                borderColor: '#D8234C',
              },
            },
            "& label.Mui-focused": {
              color: '#D8234C',
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
              {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
         
         />

        <Button style={buttonStyle} onClick={login}> Login </Button>
      </Grid>
      </CardActions>
    </Card>
    <Snackbar open={openSnackbar} autoHideDuration={6000}  onClose={() => setOpenSnackbar(false)}>
        <Alert severity="error">Invalid Creds / User</Alert>
    </Snackbar>
    </Box>
    </Grid>
    </div>
  );
};

export default Login;
