import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import * as jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import MyProfile from './components/MyProfile';
import Navo from './components/Navo';
import UserExplorer from './components/UserExplorer';
import WaitAdm from './components/WaitAdm';
function App() {
  const isAuthenticated = !!localStorage.getItem('userToken');
  const [isActive, setIsActive] = useState(null);
  const [userData, setUserData] = useState(null);
 
  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) return;
 
    const decoded = jwtDecode(userToken);
    setIsActive(decoded.Active);
  }, []); 
 
  return (
    <BrowserRouter>
      { isActive && <Navo /> }
      <Routes>
        <Route path="/" element={ isActive ? ( <Dashboard /> ) : isAuthenticated ?(<WaitAdm/>) : ( <Login/> )}/>
        <Route path="/register" element={<Register />} />
        <Route path="/Dash" element={ isActive ? ( <Dashboard /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/UserExplorer" element={ isActive ? ( <UserExplorer /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/Settings" element={ isActive ? ( <Settings /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/MyProfile" element={ isActive ? ( <MyProfile /> ) : ( <Navigate to="/" replace /> )}/>
      </Routes>
    </BrowserRouter>
  );
 }
 

export default App;
